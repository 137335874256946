import React, { useState, useEffect } from 'react';
import NavbarFront from '../../components/common/NavbarFront';
import CarouselTop from '../../components/HomePage/CarouselTop';
import { GiTrophyCup } from "react-icons/gi";
import { FaLightbulb, FaHandsHelping, FaWhatsapp } from "react-icons/fa";
import { AiOutlineSafety, AiFillWechat } from "react-icons/ai";
import { FaLine } from "react-icons/fa6";
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { DBsearch } from '../../api/DBsearch';
import { STRAPI } from '../../utils/config';
import FooterFront from '../../components/common/FooterFront';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import CopyableText from '../../utils/CopyableText';
import styles from './HomePage.module.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';

import styled from 'styled-components';
import MoreStyle from '../../components/HomePage/MoreStyle';
import { DBsearchMaterial } from '../../api/front/DBsearchMaterial';

const SwiperContainer = styled.div`
  /*carousel*/
  .swiper-wrapper{
    display: flex;
    align-items: center;
  }

  .swiper {
      width: 100%;
      height: 100%;
  }
    
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center; 
  }

  .swiper-slide img {
    display: block;
    width: auto;
    max-width: 70%;
    height: auto;
    max-height: 100%;
    object-fit: contain;
    opacity: 0.7; /* 預設情況下圖片透明度較低，顏色看起來較淡 */
    transition: opacity 0.3s ease; /* 平滑過渡效果 */
  }

  .swiper-slide img:hover {
    opacity: 1; /* 當 hover 時，透明度恢復，顏色變得更重 */
  }

  .swiper-pagination {
    position: relative;
    transform: translateY(8px);
  }
    
  .swiper-pagination-bullet {
    background-color: bdbdbd !important;
  }

  .swiper-pagination-bullet-active {
    background-color: #eb5d1e !important; /* Orange color for active state */
  }

`;

const HomePage: React.FC = () => {
  const [products, setProducts] = useState<any[]>([]);
  const [logos, setLogos] = useState<any[]>([]);
  const [news, setNews] = useState<any[]>([]);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const showProduct = async () => {
    const searchStr = { nonDel: { type: 'basic', operator: 'eq', text: true }, display: { type: 'basic', operator: 'eq', text: true } };
    const res = await DBsearchMaterial(
      'products',
      'populate[system][populate][material]=true&populate[files]=true&populate[image]=true&populate[files_en]=true',
      searchStr,
      null,
      'sort_ASC'
    );
    const paginatedProducts = res.info.slice(0, 8);  
    setProducts(paginatedProducts);
  };

  const showLogo = async () => {
    const searchStr = { display: { type: 'basic', operator: 'eq', text: true } };
    const res = await DBsearch('logos', 'populate=*', searchStr, 'sort_ASC');
    setLogos(res.info);
  };

  const handleNewsClick = (id:number) => {
    navigate(`/new/${id}`);
  };

  const showNews = async () => {
    try {
      const searchStr: any = { nonDel: { type: 'basic', operator: 'eq', text: true },display: { type: 'basic', operator: 'eq', text: true } };
      const res = await DBsearch('news', 'populate=*', searchStr, 'sort_ASC');
      setNews(res.info);
    } catch (error) {
      console.error('Fetching product name failed:', error);
      return null;
    }
  };

  const handleCardClick = (productSlug: any) => {
    const { name_en,system }=productSlug;
    const sysNameTmp=system.data.attributes.name_en;
    const sysName = sysNameTmp.replace(/\s+/g, '_');

    const productTmp=name_en.replace(/\s+/g, '_');
    const productName=productTmp.replace(/\//g, '__');

    navigate(`/product/${sysName}/${productName}`);
  };

  const handleReset = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const form = document.getElementById('contactForm') as HTMLFormElement | null;
    if (form) {
      form.reset();
    } else {
      console.error('Form not found');
    }
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();

    const name = event.target.formGroupName.value;
    const email = event.target.formGroupEmail.value;
    const subject = event.target.formGroupSubject.value;
    const message = event.target.formGroupMessage.value;

    const mailtoLink = `mailto:jetworld@hibox.hinet.net?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent("Name: " + name + "\nEmail: " + email + "\n\n" + message)}`;

    window.location.href = mailtoLink;
  };

  useEffect(() => {
    showProduct();
    showLogo();
    showNews();
  }, []);

  /*const chunkArray = (array: any[], chunkSize: number) => {
    const results = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      results.push(array.slice(i, i + chunkSize));
    }
    return results;
  };

  const categoryChunks = chunkArray(categories, 4);*/

  return (
    <>
      <NavbarFront />
      <CarouselTop />

      <Container fluid className='section-title'>
        <Row className='pt-5'>
          <Col md={{ span: 8, offset: 2 }} className='pt-4'>
            <h2 className='video-title text-center' data-aos="fade-zoom-in" data-aos-offset="100" data-aos-easing="ease-in-sine" data-aos-duration="600"><strong>{t('About Us')}</strong></h2>
            <p className='mt-4 mb-4'>
              {t('index intro')}
            </p>
          </Col>
        </Row>
        <Row className='pt-3 pb-5 justify-content-center'>
          <Col md={{ span: 8 }}>
            <Row>
              {[AiOutlineSafety, GiTrophyCup, FaLightbulb, FaHandsHelping].map((Icon, index) => (
                <Col key={index} data-aos="flip-up" data-aos-offset="100" data-aos-easing="ease-in-sine" data-aos-duration="600">
                  <Card className='text-center mb-2 card-intro border border-0'>
                    <Card.Body>
                      <div className='icon-circle'>
                        <Icon color='white' size={60} />
                      </div>
                    </Card.Body>
                    <Card.Title>
                      <h4 className='icon-title'>{
                        [t('Integrity'), t('Excellence'), t('Innovation'), t('Mutual Benefit')][index]
                      }</h4>
                    </Card.Title>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>

      <Container fluid className='section-title'>
        <Row className='justify-content-center'>
          <Col md={{ span: 12 }}>
            <Row>
              <Col md={4} className='pb-5'>
                <div className="video-title-container mt-3 mb-2" data-aos="flip-up" data-aos-offset="500" data-aos-easing="ease-in-sine" data-aos-duration="600">
                  <span className="vertical-line"></span>
                  <span className="video-title"><h5><strong>{t('Company Introduction Video')}</strong></h5></span>
                </div>
                <video width="100%" controls>
                  <source src="/images/index/video/video3.mp4" type="video/mp4" />
                </video>
              </Col>
              <Col md={4} className='pb-5'>
                <div className="video-title-container mt-3 mb-2" data-aos="flip-up" data-aos-offset="500" data-aos-easing="ease-in-sine" data-aos-duration="600">
                  <span className="vertical-line"></span>
                  <span className="video-title"><h5><strong>{t('Exhibition Video')}</strong></h5></span>
                </div>
                <iframe
                  width="100%"
                  height="390"
                  src="https://www.youtube.com/embed/6axjb7Y10zs?si=loUHSXwjS_GVMAky"
                  title="Exhibition Video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen>
                </iframe>
              </Col>
              <Col md={4} className='pb-5'>
                <div className="video-title-container mt-3 mb-2" data-aos="flip-up" data-aos-offset="500" data-aos-easing="ease-in-sine" data-aos-duration="600">
                  <span className="vertical-line"></span>
                  <span className="video-title"><h5><strong>{t('Exhibition Video2')}</strong></h5></span>
                </div>
                <iframe
                  width="100%"
                  height="390"
                  src="https://www.youtube.com/embed/kgy0BpFqvY8?si=9BdizdNOPaETh8hA"
                  title="Exhibition Video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen>
                </iframe>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      
      <Container fluid className='section-title' style={{ backgroundColor: 'white' }} data-aos="fade-up" data-aos-offset="600" data-aos-easing="ease-in-sine" data-aos-duration="600">
        <Row className='p-5 text-center pb-2'>
          <Col xs={12}>
            <h2 className='video-title '><strong>{t('Our Teams')}</strong></h2>
            <p className='mt-4'>
              {t('Team intro')}
            </p>
          </Col>
        </Row>
        <Container className='pb-5'>
          {
            logos.length >= 3 && (
              <SwiperContainer>
                <Swiper
                    modules={[Pagination, Autoplay]}
                    pagination={{ clickable: true }}
                    autoplay={{ delay: 3000, disableOnInteraction: true }}
                    spaceBetween={10}
                    slidesPerView={logos.length === 1 ? 1 : 3}
                    loop={logos.length>4} 
                  >
                  {(() => {
                    let logosToRender = [...logos]; 

                    return logosToRender.map((value: any, index: number) => {
                      const { image } = value.attributes;
                      let imgName;
                      if (image && image.data && image.data.attributes && image.data.attributes.url) {
                        imgName = STRAPI + image.data.attributes.url;
                      }
                      return (
                        <SwiperSlide key={index} className={logos.length === 1 ? 'd-flex justify-content-center' : ''}>
                          <img className='img-fluid' src={imgName} alt='logo.png' />
                        </SwiperSlide>
                      );
                    });
                  })()}
                </Swiper>
              </SwiperContainer>
            )
          }
         {logos.length <= 2 && (
          <Row className='d-flex justify-content-center align-items-center'>
            {logos.map((value: any, index: number) => {
              const { image } = value.attributes;
              const imgUrl = image?.data?.attributes?.url ? STRAPI + image.data.attributes.url : null;

              return (
                <Col key={index} className="text-center">
                  {imgUrl && <img className='img-fluid homepage-logo-size' src={imgUrl} alt={`Logo ${index}`}  />}
                </Col>
              );
            })}
          </Row>
          )}
        </Container>
      </Container>

      <Container fluid className='section-title' data-aos="fade-up" data-aos-offset="600" data-aos-easing="ease-in-sine" data-aos-duration="600">
        <Row className='p-5 pb-3'>
          <Col xs={12}>
            <h2 className='video-title text-center'><strong>{t('News')}</strong></h2>
          </Col>
        </Row>
        <Row>
          <Col md={{ offset: 1, span: 10 }} className='pb-5'>
            <Row>
              {
                news.length>0 && news.slice(0, 5).map((item: any,index:number) => {
                  const id = item.id;
                  const { title_en, title_zh, newsgroup, createdAt } = item.attributes;
                  const createDate = createdAt.split('T')[0].replace(/-/g, '/');
    
                  const group_en=newsgroup.data.attributes.name_en;
                  const group_zh=newsgroup.data.attributes.name_zh;
                  return (
                    <Col md={12} className='my-3' key={index}>
                      <Card className='shadow-sm text-dark p-3 custom-news-card' role="button" onClick={() => handleNewsClick(id)}>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <span className='text-muted mx-2 news-txt-date'>{createDate}</span>
                            <span className={`news-txt-tag p-1 mx-2 rounded ${i18n.language === 'en' && 'news-txt-tag-en'}`}>
                              {i18n.language === 'en' ? group_en : group_zh}
                            </span>
                            <b className='news-txt-title mx-2'>
                              {i18n.language === 'en' ? title_en : title_zh}
                            </b>
                          </div>
                          <button className='btn btn-link news-link' onClick={() => handleNewsClick(id)}>
                            <MoreStyle />
                          </button>
                        </div>
                      </Card>
                    </Col>
                  );
                })
              }
              <Col md={12} className='text-center mt-5'>
                <button className='btn btn-outline-secondary btn-lg px-5 rounded-0 news-moreBtn'
                  onClick={() => navigate('/new')}>
                  MORE
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container fluid className='section-title' style={{ backgroundColor: 'white' }} data-aos="fade-up" data-aos-offset="600" data-aos-easing="ease-in-sine" data-aos-duration="600">
        <Row className='p-5 pb-1'>
          <Col xs={12}>
            <h2 className='video-title text-center'><strong>{t('Product')}</strong></h2>
          </Col>
        </Row>
        <Row>
        <Col md={{ offset: 1, span: 10 }} className='pb-5'>
          <Row>
            {products.length > 0 && products.map((product, index) => (
              <Col lg={3} sm={12} className="d-flex align-items-stretch my-2" key={index}>
                <Card className={`${styles.productContant} product-card shadow-sm`} 
                  role="button" onClick={() => handleCardClick(product.attributes)}>
                  <Card.Body>
                    <Card.Img className="img-fluid pt-3" variant='top' src={`${STRAPI}${product.attributes.image?.data?.attributes.url}`} alt={product.attributes.name_en} />
                    <div className={`${styles.hoverOverlay}`}>
                      <h5 className='text-start'><b>{i18n.language === 'en' ? product.attributes.name_en : product.attributes.name_zh}</b></h5>
                      <p className="text-white ms-3 shadow-sm">
                        {i18n.language === 'en'
                          ? product.attributes.system?.data?.attributes.material?.data?.attributes.name_en
                          : product.attributes.system?.data?.attributes.material?.data?.attributes.name_zh}
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
        
        </Row>
      </Container>

      {/* <Container fluid className='section-title' style={{ backgroundColor: 'white' }} data-aos="fade-up" data-aos-offset="600" data-aos-easing="ease-in-sine" data-aos-duration="600">
        <Row className='p-5'>
          <Col xs={12}>
            <h2 className='video-title text-center'><strong>{t('Product')}</strong></h2>
          </Col>
        </Row>
        <Row>
          <Col md={{ offset: 2, span: 8 }} className='pb-5'>
            {categories.length > 4 ? (
              <Carousel className="custom-carousel">
                {categoryChunks.map((chunk, chunkIndex) => (
                  <Carousel.Item key={chunkIndex}>
                    <Row>
                      {chunk.map((value: any, index: number) => {
                        const { name_zh, name_en, image } = value.attributes;
                        let imgName;
                        if (image && image.data && image.data.attributes && image.data.attributes.url) {
                          imgName = STRAPI + image.data.attributes.url;
                        }
                        const colProps = index % 2 === 0 ? { md: { offset: 2, span: 4 } } : { md: 4 };

                        return (
                          <Col {...colProps} key={index}>
                            <div className='product-card' role='button' onClick={handleCardClick(name_en)}>
                              <div className='image-container'>
                                <img className="img-fluid mt-5" src={imgName} alt={name_zh} />
                              </div>
                              <div className="product-info">
                                <h5 className='bg-light-orange p-2 rounded'>
                                  {i18n.language === 'en' ? name_en : name_zh}
                                </h5>
                              </div>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </Carousel.Item>
                ))}
              </Carousel>
            ) : (
              <Row>
                {categories.map((value: any, index: number) => {
                  const { name_zh, name_en, image } = value.attributes;
                  let imgName;
                  if (image && image.data && image.data.attributes && image.data.attributes.url) {
                    imgName = STRAPI + image.data.attributes.url;
                  }
                  const colProps = index % 2 === 0 ? { md: { offset: 2, span: 4 } } : { md: 4 };

                  return (
                    <Col {...colProps} key={index}>
                      <div className='product-card' role='button' onClick={handleCardClick(name_en)}>
                        <div className='image-container'>
                          <img className="img-fluid mt-5" src={imgName} alt={name_zh} />
                        </div>
                        <div className="product-info">
                          <h5 className='bg-light-orange p-2 rounded'>
                            {i18n.language === 'en' ? name_en : name_zh}
                          </h5>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            )}
          </Col>
        </Row>
      </Container> */}

      <Container fluid className='section-title' data-aos="fade-up" data-aos-offset="750" data-aos-easing="ease-in-sine" data-aos-duration="600">
        <Row className='p-5'>
          <Col xs={12}>
            <h2 className='video-title text-center'><strong>{t('Contact Us')}</strong></h2>
          </Col>
        </Row>

        <Row className='justify-content-center mb-4'>
          <Col md={{ span: 8, offset: 2 }}>
            <Row>
              <Col md={6} className='mb-2'>
                <Row>
                  <h4>{t('Address')}</h4>
                  <CopyableText text={t('AddressInfo')} tooltipText={`${t('copyText')}${t('Address')}`} />
                  <h4>{t('Phone')}</h4>
                  <CopyableText text="+886-2-2392-5866" tooltipText={`${t('copyText')}${t('Phone')}`} />
                  <h4>{t('Fax')}</h4>
                  <CopyableText text="+886-2-2392-5881" tooltipText={`${t('copyText')}${t('Fax')}`} />
                  <h4>{t('Email')}</h4>
                  <CopyableText text="jetworld@hibox.hinet.net" tooltipText={`${t('copyText')}${t('Email')}`} />
                </Row>
                <div className="d-inline-flex">
                  <CopyableText text="rogerhung1988" tooltipText={`${t('copyText')}Line ID`}>
                    <button className='btn btn-outline-dark'>
                      <FaLine size={30} />
                    </button>
                  </CopyableText>&emsp;
                  <CopyableText text="rogerhung1988" tooltipText={`${t('copyText')}WeChat ID`}>
                    <button className='btn btn-outline-dark'>
                      <AiFillWechat size={30} />
                    </button>
                  </CopyableText>&emsp;
                  <CopyableText text="+86 18820770402" tooltipText={`${t('copyText')}WhatsApp`}>
                    <button className='btn btn-outline-dark'>
                      <FaWhatsapp size={30} />
                    </button>
                  </CopyableText>
                </div>
              </Col>
              <Col md={6} className='mb-2'>
                <Form id="contactForm" className="gy-4" onSubmit={handleSubmit}>
                  <Form.Group controlId="formGroupName" className='mb-3'>
                    <Form.Label>{t('Your Name')}</Form.Label>
                    <Form.Control type="text" />
                  </Form.Group>

                  <Form.Group controlId="formGroupEmail" className='mb-3'>
                    <Form.Label>{t('Your Email')}</Form.Label>
                    <Form.Control type="email" />
                  </Form.Group>

                  <Form.Group controlId="formGroupSubject" className='mb-3'>
                    <Form.Label>{t('Subject')}</Form.Label>
                    <Form.Control type="text" />
                  </Form.Group>

                  <Form.Group controlId="formGroupMessage" className='mb-3'>
                    <Form.Label>{t('Message')}</Form.Label>
                    <Form.Control as="textarea" rows={3} />
                  </Form.Group>

                  <div className="text-center pt-3">
                    <Button variant="outline-secondary" className='px-5' onClick={handleReset}>{t('clear')}</Button>
                    &emsp;
                    <Button variant='dark' className='px-5' type="submit">{t('send')}</Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <FooterFront />
    </>
  );
};

export default HomePage;